import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Popup, AdvertisingDisclosure } from "../../components";
import { navFooter } from "../../data";
import { useWindowSize } from "../../utils/useWindowSize";

import "./Footer.scss";

const Nav = () => {
  const [open, isOpen] = useState(false);

  const handleOpen = () => isOpen(!open);

  return (
    <>
      {open && (
        <Popup title="Advertising Disclosure" onClose={() => isOpen(false)}>
          <AdvertisingDisclosure />
        </Popup>
      )}
      <ul className="footer__nav">
        {navFooter.map(({ name, link }, index) => (
          <li key={index}>
            <Link to={link}>{name}</Link>
          </li>
        ))}
        <li onClick={handleOpen}>
          <div>Advertising Disclosure</div>
        </li>
      </ul>
    </>
  );
};

export const Footer = () => {
  const size = useWindowSize().width;

  return (
    <div id="footer" className="footer section">
      <div className="container">
        <div className="footer__row">
          <div className="footer__menu">
            <div className="footer__menu-images">
              <img src="/images/eighteen.png" alt="18+" />
              <a
                href="https://www.begambleaware.org/"
                style={{ borderRadius: "10px", padding: "5px" }}
                className="footer_menu_link">
                <img src="/images/begambleaware.png" alt="" />
              </a>
            </div>
            <div className="footer__menu-warning">
              {size < 1367 && <img src="/images/eighteen.png" alt="18+" />}
              <div>GAMBLING CAN BE ADDICTIVE. PLEASE PLAY RESPONSIBLY.</div>
              {size < 1367 && (
                <a
                  href="https://www.begambleaware.org/"
                  style={{ borderRadius: "10px", padding: "5px" }}
                  className="footer_menu_link">
                  <img src="/images/begambleaware.png" alt="" />
                </a>
              )}
            </div>
            <Nav />
          </div>
        </div>
      </div>
    </div>
  );
};

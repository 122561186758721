import { offers } from "./offers";

export const navHeader = [
  {
    name: "Home",
    link: "/",
    children: [],
  },
  {
    name: "Reviews",
    link: "/reviews",
    children: offers.map(offer => ({
      name: offer.name,
      link: offer.review,
    })),
  },
];

export const navFooter = [
  {
    name: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    name: "Terms & Conditions",
    link: "/terms-conditions",
  },
];

import React from "react";
import "./CasinoSlider.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { offers } from "../../data";

import TrackingLink from "../TrackingLink";
import { Link } from "react-router-dom";

export const CasinoSlider = ({ width }) => {
  const showSlider = () => {
    const windowSize = [
      { winSize: 1367, slide: 3 },
      { winSize: 991, slide: 2 },
      { winSize: 769, slide: 1 },
    ];
    let slidesToShow = 4;
    for (let i = 0; i < windowSize.length; i++) {
      const max = windowSize[i].winSize;
      const min = windowSize[i + 1] ? windowSize[i + 1].winSize : 0;
      if (width >= min && width < max) {
        slidesToShow = windowSize[i].slide;
        break;
      }
    }
    return slidesToShow;
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: showSlider(),
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  return (
    <div className="container">
      <h2 className="green-text">Other Top Casinos to Consider</h2>
      <Slider {...settings} className="slider">
        {offers.map((item, index) => (
          <div key={index} className="slide-item">
            <div className="slide-item-container">
              <div className="slide-item-container__logo">
                <img src={item.logo} alt="logo" />
              </div>
              <div className="slide-item-container__description">
                <p>{item.descriptionSlider}</p>
                <span>T&Cs Apply</span>
              </div>
              <div className="slide-item-container__button">
                <TrackingLink
                  href={item.link}
                  className={width > 580 ? "button-1-outline" : "button-1-mobile"}>
                  go to site
                </TrackingLink>
                <Link
                  to={item.review}
                  className={width > 580 ? "button-2-full mt-8" : "button-2-mobile mt-8"}>
                  read review
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

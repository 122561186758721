import React from "react";

export const AdvertisingDisclosure = () => (
  <>
    <p>
      At Top Review Casino we provide information on offers and bonuses from several different
      online casinos. This site is funded by referral fees (monetary compensations) from the
      companies whose online casinos we place on this site. All of the offers and bonuses have
      their own specific Terms and Conditions. We recommend that all visitors read those terms
      and conditions carefully before making a deposit.
    </p>
  </>
);

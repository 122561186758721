export const howToChoose = [
  {
    title: "Do your research",
    text:
      "It's always a good idea to read a few online casino reviews before making your choice, to compare what different casinos have to offer. You can also check our casino blacklist to avoid any particularly dangerous or untrustworthy sites.",
  },
  {
    title: "Make sure it's available in your country",
    text:
      "Not all online casinos accept players from everywhere in the world. Even if they do, they may not offer support that's particularly suitable for every country. Get in touch with customer service before joining if you're unsure.",
  },
  {
    title: "Test the free games",
    text:
      "Many casinos offer free games that you can try without having to create an account. This is a great way to get a feel for some of their games and see if you like them before depositing any real money.",
  },
  {
    title: "Understand bonus requirements",
    text:
      "More often than not, the bigger the bonus, the better it is. While this may be true, you need to examine the bonus wagering requirements too. It's important to know how much you need to play through in order to claim your winnings.",
  },
  {
    title: "Check the payout speed",
    text:
      "There's nothing worse than winning a huge jackpot and then sitting and waiting for weeks or even months begore you can claim it. You'll want an online casino with a fast payout time.",
  },
];

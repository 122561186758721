import React from "react";

export const StarHalf = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0997 0L13.5918 7.60081H21.6564L15.132 12.2984L17.6241 19.8992L11.0997 15.2016L4.57528 19.8992L7.06738 12.2984L0.542969 7.60081H8.60759L11.0997 0ZM18.5561 8.60081H12.8673L11.0997 3.20971V13.9694L15.7177 17.2943L13.9538 11.9145L18.5561 8.60081Z"
      fill="currentColor"
    />
  </svg>
);

import React from "react";

import RollbarErrorTracking from "./RollbarErrorTracking";

class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === "production") {
      RollbarErrorTracking.logErrorInfo(info);
      RollbarErrorTracking.logErrorInRollbar(error);
    }
  }
  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;

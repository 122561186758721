import React from "react";

import { ProsCons } from "../../../components";

export const CookieCasino = () => (
  <div className="container">
    <div className="text-review-block">
      <h2>Cookie Casino - online casino review</h2>
      <p>
        It’s time to enjoy a sweet treat at Cookie Casino thanks to a generous welcome package.
        You can claim bonuses when making each of your first two deposits and use them to play
        a top variety of slots, table games, and video poker. Our Cookie Casino review experts
        also love the minimum and maximum deposit limits, which means you don’t have to bite
        off more than you can chew.
      </p>
      <h3>Procs and cons</h3>
      <ProsCons
        pros={[
          "Home to leading software developers",
          "Withdrawals within 24 hours one processed",
          "User friendly interface",
          "Mobile friendly design",
        ]}
        cons={["Players from certain countries are not accepted"]}
      />
      <p>
        Both casino bonuses also come with an additional incentive of free spins that you can
        use to play designated slots. Wagering requirements for the whole welcome package are
        very reasonable, which is another reason why we’re happy to recommend you take
        advantage of this offer while it’s still available.
      </p>
      <p>
        Weekly free spins are also available to existing players, as are regular reload
        bonuses. You can even enjoy thirty different tiers of delicious wins in the VIP
        program, where it’s possible to upgrade to levels such as marshmallow, jelly, and
        cupcake. Our Cookie Casino review team thinks these awesome promotions and rewards are
        big reasons to play regularly at this top gaming site.
      </p>
      <p>
        A great choice of tournaments is another reason to sink your teeth into Cookie Casino.
        You can choose from slot races and casino game tournaments and making deposits will
        earn you tickets for lottery draws. With leaderboards and lots of prizes to win, these
        tourneys are another sweet reason to sign up to Cookie Casino.
      </p>
    </div>
  </div>
);

import React from "react";
import { Star, StarEmpty, StarHalf } from "./stars";
import "./Rating.scss";

const numRound = num => {
  const d = Math.floor(num);
  const i = num % d;

  return i < 0.25 ? d : i < 0.75 ? d + 0.5 : d + 1;
};

const countingStars = rating => {
  const MAX_STARS = 5;

  const starsNotSelect = MAX_STARS - numRound(rating);
  const starsSelect = MAX_STARS - starsNotSelect;

  const isInteger = num => (num ^ 0) === num;

  if (isInteger(starsNotSelect)) {
    return {
      starsSelect: starsSelect,
      starHalf: 0,
      starsNotSelect: starsNotSelect,
    };
  } else {
    return {
      starsSelect: starsSelect - 0.5,
      starHalf: 1,
      starsNotSelect: starsNotSelect - 0.5,
    };
  }
};

export const Rating = ({ rating }) => {
  const starsHTML = [];

  const { starsSelect, starHalf, starsNotSelect } = countingStars(rating);

  for (let i = 0; i < starsSelect; ++i) {
    starsHTML.push(
      <div key={`starsSelect-${i}`} className="rating__star">
        <Star />
      </div>,
    );
  }

  for (let i = 0; i < starHalf; ++i) {
    starsHTML.push(
      <div key={`starHalf-${i}`} className="rating__star">
        <StarHalf />
      </div>,
    );
  }

  for (let i = 0; i < starsNotSelect; ++i) {
    starsHTML.push(
      <div key={`starsNotSelect-${i}`} className="rating__star">
        <StarEmpty />
      </div>,
    );
  }

  return <div className="rating">{starsHTML}</div>;
};

Rating.defaultProps = {
  rating: 0,
};

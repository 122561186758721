import React, { useState } from "react";

import SlideToggle from "react-slide-toggle";

import "./HowDoChoose.scss";

import { Link } from "react-scroll";

import { howToChoose } from "../../data";

const HowDoChoose = () => {
  const [toggleButton, setToggleButton] = useState(false);

  const toggleShowButton = () => {
    setToggleButton(!toggleButton);
  };

  return (
    <SlideToggle
      duration={800}
      collapsed
      render={({ toggle, setCollapsibleElement, progress }) => (
        <div>
          <p
            style={toggleButton ? { visibility: "hidden" } : {}}
            className="toggle-text-less"
            onClick={toggle}>
            <span
              onClick={() => {
                toggleShowButton();
              }}>
              {" "}
              Show more{" "}
            </span>
          </p>
          <div ref={setCollapsibleElement}>
            <div
              style={{
                padding: "1rem",
                minHeight: "20rem",
                transform: `translateY(${Math.round(20 * (-1 + progress))}px)`,
              }}>
              <h3 className="orange-text mt-80">
                How do I choose the right casino?
                <br /> Editor-in-chief David Sheldon shares his top 5 tips
              </h3>
              <div>
                {howToChoose.map((item, index) => (
                  <div key={index} className="how-choose-item">
                    <p className="how-choose-item__index">{index + 1}</p>
                    <p className="how-choose-item__title">{item.title}</p>
                    <p className="how-choose-item__text">{item.text}</p>
                  </div>
                ))}
              </div>

              <Link
                to="less-text"
                smooth={true}
                duration={900}
                className="toggle-text-more"
                onClick={toggle}>
                <span
                  onClick={() => {
                    toggleShowButton();
                  }}>
                  Show less
                </span>
              </Link>
            </div>
          </div>
        </div>
      )}
    />

    // <div className={show ? "how-do-choose how-do-choose__open" : "how-do-choose"}>
    //   <h3 className="orange-text mt-80">
    //     How do I choose the right casino?
    //     <br /> Editor-in-chief David Sheldon shares his top 5 tips
    //   </h3>
    //   <div>
    //     {howToChoose.map((item, index) => (
    //       <div key={index} className="how-choose-item">
    //         <p className="how-choose-item__index">{index + 1}</p>
    //         <p className="how-choose-item__title">{item.title}</p>
    //         <p className="how-choose-item__text">{item.text}</p>
    //       </div>
    //     ))}
    //   </div>
    //   {show && (
    //     <Link
    //       to="less-text"
    //       smooth={true}
    //       duration={900}
    //       className="toggle-text-more"
    //       onClick={() => {
    //         isToggle();
    //       }}>
    //       Show less
    //     </Link>
    //   )}
    // </div>
  );
};

export default HowDoChoose;

import React from "react";

import "./OurCriteria.scss";

import { ourCriteria } from "../../data";

export const OurCriteria = () => {
  return (
    <div className="container">
      <h2 className="green-text">Our online casino review criteria</h2>
      <div className="our-criteria">
        {ourCriteria.map((elem, index) => (
          <div key={index} className="our-criteria-container">
            <div key={index} className="our-criteria__item">
              <img className="our-criteria__item-images" src={elem.images} alt="images" />
              <h3 className="our-criteria__item-title">{elem.title}</h3>
              <p>{elem.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

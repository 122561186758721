import React from "react";

export const Star = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2002 0L13.6698 7.60081H21.6618L15.1962 12.2984L17.6658 19.8992L11.2002 15.2016L4.73456 19.8992L7.20421 12.2984L0.738574 7.60081H8.73054L11.2002 0Z"
      fill="currentColor"
    />
  </svg>
);

import React from "react";

import Loadable from "react-loadable";
import { Route, Switch } from "react-router-dom";

import { Main } from "./Main";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Loading } from "./Loading";

import LvlUp from "../pages/promo/LvlUp";
import Euslot from "../pages/promo/euslot";

import { ScrollToTop } from "../components";

import { offers } from "../data";

const Home = Loadable({
  loader: () => import("../pages/home"),
  loading: Loading,
});

const HomeDa = Loadable({
  loader: () => import("../pages/homeDa"),
  loading: Loading,
});

const TermsConditions = Loadable({
  loader: () => import("../pages/terms-conditions"),
  loading: Loading,
});

const Reviews = Loadable({
  loader: () => import("../pages/reviews"),
  loading: Loading,
});

const PrivacyPolicy = Loadable({
  loader: () => import("../pages/privacy-policy"),
  loading: Loading,
});

function App() {
  React.useEffect(() => {
    function getQueryVariable(variable) {
      const query = window.location.search.substring(1);
      const vars = query.split("&");

      for (let i = 0; i < vars.length; ++i) {
        const pair = vars[i].split("=");

        if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
        }
      }
    }

    if (localStorage.getItem("tid") === null) {
      localStorage.setItem("tid", getQueryVariable("gclid") || "organic");
    }
  }, []);

  return (
    <ScrollToTop>
      <Header />
      <Main>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/da" component={HomeDa} />
          <Route exact path="/promo/lvlup" component={LvlUp} />
          <Route exact path="/promo/euslot" component={Euslot} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-conditions" component={TermsConditions} />
          {offers.map(({ name, review }) => (
            <Route exact key={name} path={review} component={Reviews} />
          ))}
        </Switch>
      </Main>
      <Footer />
    </ScrollToTop>
  );
}

export default App;

import React from "react";
import SlideToggle from "react-slide-toggle";

import { EuslotContent } from "../../../components";
import TrackingLink from "../../../components/TrackingLink";

import "./euslot.scss";

function Euslot() {
  return (
    <>
      <div className="wrapper">
        <div className="lvl-container">
          <TrackingLink className="logo" href="/go/euslotpromo">
            <img src="/images/euslot.png" alt="logo" />
          </TrackingLink>
          <div className="center">
            <div className="bonus">
              <div className="bonus__1 bold-text">Welcome Package</div>
              <div className="bonus__2 bold-text">
                100% UP TO €300 <br />+ 100 FS
              </div>
            </div>
            <div className="get-code">
              <div className="get-code__2">
                <TrackingLink className="button-claim bold-text" href="/go/euslotpromo">
                  Claim Now
                </TrackingLink>
              </div>
              <div className="wolf-img"></div>
              <div className="get-code__3">
                <SlideToggle
                  duration={800}
                  collapsed
                  render={({ toggle, setCollapsibleElement, progress }) => (
                    <div>
                      <div className="button-terms" onClick={toggle} id="btn-open-popup">
                        Terms and Conditions
                      </div>
                      <div
                        ref={setCollapsibleElement}
                        style={{
                          background: `rgba(37, 170, 214, ${progress})`,
                        }}
                        className="popup__terms">
                        <div
                          style={{
                            padding: "0 1rem 10px",
                            transform: `translateY(${Math.round(15 * (0 + progress))}px)`,
                            borderRadius: " 8px",
                          }}>
                          Welcome package is valid only for new players for 14 days upon
                          registration and consists of 4 bonuses.
                          <ul
                            style={{
                              padding: "18px",
                            }}>
                            <li>
                              WOLF: 100% up to 100 AUD/CAD/EUR/NZD/USD + 100 FS, WG x40, valid
                              on the 1st deposit.
                            </li>
                            <li>
                              WOLF2: 50% up to 100 AUD/CAD/EUR/NZD/USD + 20 FS, WG x40, valid
                              on the 2nd deposit.
                            </li>
                            <li>
                              WOLF3: 50% up to 200 AUD/CAD/EUR/NZD/USD, WG x40, valid on the
                              3rd deposit.
                            </li>
                            <li>
                              WOLF4: 100% up to 200 AUD/CAD/EUR/NZD/USD + 20 FS, WG x40, valid
                              on the 4th deposit.
                            </li>
                          </ul>
                          Min dep: 20 AUD/CAD/EUR/NZD/USD, 200 NOK, 1500 RUB, 90 PLN, 2000 JPY,
                          9000 KZT, 300 ZAR. Max bet during the wagering: 5 EUR/USD, 6.5
                          CAD/AUD/NZD, 50 NOK, 350 RUB, 20 PLN, 600 JPY, 2,100 KZT. All Free
                          Spins are valid for 24h after the deposit, WG winnings x40.
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="wolf"></div>
        <div className="moon"></div>
      </div>
      <EuslotContent />
    </>
  );
}

export default Euslot;

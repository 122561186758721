import React, { useEffect, useRef } from "react";

import "./Popup.scss";

export const Popup = ({ title, children, onClose }) => {
  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    onClose();
  };

  return (
    <>
      <div className="popup__bg" onClick={onClose}></div>
      <div className="popup__wrapper">
        <div className="popup__container">
          <div className="popup__content">
            <div className="popup__center" ref={node}>
              <div className="popup__title">{title}</div>
              <div className="popup__body">
                {children}
                <div className="popup__body__bottom-bl">
                  <button type="button" className="btn-danger" onClick={() => onClose()}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React from "react";

import HowDoChoose from "../HowDoChoose";

export const ReviewText = () => {
  return (
    <div className="container">
      <h2 className="green-text">
        Independent Ratings and Reviews <br className="br" />
        of Online Casinos
      </h2>
      <h3 className="orange-text">
        Our independent reviewers rank the best casinos in <br className="br" /> September 2020
      </h3>
      <p id="less-text">
        With so many online casinos out there to choose from, sometimes you might become a
        little overwhelmed. Our team of experts personally test and review online{" "}
        <span className="review-text-hide">
          {" "}
          casinos to find you the best sites. All our casino reviews are based on a number of
          important casino comparison criteria, to help you get a feel for each casino’s
          gameplay and overall experience. Read on to discover more about our reviews and join
          the thousands of other players around the world who we've helped to find the perfect
          casino!
        </span>
      </p>
      <HowDoChoose />
    </div>
  );
};

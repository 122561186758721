import React from "react";

import HowDoChooseDa from "../HowDoChooseDa";

export const ReviewTextDa = () => {
  return (
    <div className="container">
      <h2 className="green-text">
        Uafhængige vurderinger og anmeldelser <br className="br" />
        af online casinoer
      </h2>
      <h3 className="orange-text">
        Vores uafhængige anmeldere rangerer de bedste kasinoer i <br className="br" />{" "}
        september 2020
      </h3>
      <p id="less-text">
        Med så mange online casinoer derude at vælge imellem, kan du nogle gange blive lidt
        overvældet. Vores team af eksperter tester og gennemgår online{" "}
        <span className="review-text-hide">
          {" "}
          casinoer personligt for at finde de bedste sider. Alle vores casinovurderinger er
          baseret på en række vigtige kriterier for sammenligning af kasinoer for at hjælpe dig
          med at få en fornemmelse for hvert casinos gameplay og generelle oplevelse. Læs
          videre for at finde ud af mere om vores anmeldelser og slutte dig til tusindvis af
          andre spillere over hele verden, som vi har hjulpet med at finde det perfekte casino!
        </span>
      </p>
      <HowDoChooseDa />
    </div>
  );
};

import React from "react";

import { useWindowSize } from "../../utils/useWindowSize";

import "./ProsCons.scss";

export const ProsCons = ({ pros, cons }) => {
  const width = useWindowSize().width;

  return (
    <div className="pros-cons">
      <div className="pros-cons__row">
        {width > 580 ? (
          Array(Math.max(pros.length, cons.length))
            .fill()
            .map((_, index) => {
              const itemA = pros[index] ? (
                <li className="left">{pros[index]}</li>
              ) : (
                <li className="none" />
              );
              const itemB = cons[index] ? (
                <li className="right">{cons[index]}</li>
              ) : (
                <li className="none" />
              );

              return (
                <ul key={index} className="pros-cons__list pros-cons__list__pros">
                  {itemA} {itemB}
                </ul>
              );
            })
        ) : (
          <div>
            <div className="pros-cons__col">
              <ul className="pros-cons__list pros-cons__list__pros">
                {pros.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>

            <div className="pros-cons__col">
              <ul className="pros-cons__list pros-cons__list__cons">
                {cons.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {/* <div>
            <div className="pros-cons__col">
              <ul className="pros-cons__list pros-cons__list__pros">
                {pros.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>

            <div className="pros-cons__col">
              <ul className="pros-cons__list pros-cons__list__cons">
                {cons.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div> */}
      </div>
    </div>
  );
};

ProsCons.defaultProps = {
  pros: [],
  cons: [],
};

import React, { useState } from "react";

import SlideToggle from "react-slide-toggle";

import "./HowDoChoose.scss";

import { Link } from "react-scroll";

// import { howToChoose } from "../../data";

const HowDoChoose = () => {
  const [toggleButton, setToggleButton] = useState(false);

  const toggleShowButton = () => {
    setToggleButton(!toggleButton);
  };
  const howToChooseDa = [
    {
      title: "Gør din forskning",
      text:
        "Det er altid en god ide at læse et par online casino anmeldelser, inden du foretager dit valg, for at sammenligne hvad forskellige kasinoer har at tilbyde. Du kan også tjekke vores casinosortliste for at undgå særligt farlige eller upålidelige sider.",
    },
    {
      title: "Sørg for, at den er tilgængelig i dit land",
      text:
        "Ikke alle online kasinoer accepterer spillere fra overalt i verden. Selvom de gør det, tilbyder de muligvis ikke support, der er særlig velegnet til alle lande. Kom i kontakt med kundeservice, før du tilmelder dig, hvis du er i tvivl.",
    },
    {
      title: "Test de gratis spil",
      text:
        "Mange kasinoer tilbyder gratis spil, som du kan prøve uden at skulle oprette en konto. Dette er en fantastisk måde at få en fornemmelse af nogle af deres spil og se, om du kan lide dem, før du deponerer rigtige penge.",
    },
    {
      title: "Forstå bonuskrav",
      text:
        "Oftere end ikke, jo større bonus, jo bedre er den. Selvom dette kan være sandt, skal du også undersøge kravene til bonusindsatser. Det er vigtigt at vide, hvor meget du skal spille igennem for at gøre krav på dine gevinster.",
    },
    {
      title: "Kontroller udbetalingshastigheden",
      text:
        "Der er ikke noget værre end at vinde en kæmpe jackpot og derefter sidde og vente i uger eller endda måneder, før du kan gøre krav på det. Du vil have et online casino med hurtig udbetalingstid.",
    },
  ];
  return (
    <SlideToggle
      duration={800}
      collapsed
      render={({ toggle, setCollapsibleElement, progress }) => (
        <div>
          <p
            style={toggleButton ? { visibility: "hidden" } : {}}
            className="toggle-text-less"
            onClick={toggle}>
            <span
              onClick={() => {
                toggleShowButton();
              }}>
              {" "}
              Show more{" "}
            </span>
          </p>
          <div ref={setCollapsibleElement}>
            <div
              style={{
                padding: "1rem",
                minHeight: "20rem",
                transform: `translateY(${Math.round(20 * (-1 + progress))}px)`,
              }}>
              <h3 className="orange-text mt-80">
                Hvordan vælger jeg det rigtige casino?
                <br /> Chefredaktør David Sheldon deler sine top 5 tip
              </h3>
              <div>
                {howToChooseDa.map((item, index) => (
                  <div key={index} className="how-choose-item">
                    <p className="how-choose-item__index">{index + 1}</p>
                    <p className="how-choose-item__title">{item.title}</p>
                    <p className="how-choose-item__text">{item.text}</p>
                  </div>
                ))}
              </div>

              <Link
                to="less-text"
                smooth={true}
                duration={900}
                className="toggle-text-more"
                onClick={toggle}>
                <span
                  onClick={() => {
                    toggleShowButton();
                  }}>
                  Show less
                </span>
              </Link>
            </div>
          </div>
        </div>
      )}
    />

    // <div className={show ? "how-do-choose how-do-choose__open" : "how-do-choose"}>
    //   <h3 className="orange-text mt-80">
    //     How do I choose the right casino?
    //     <br /> Editor-in-chief David Sheldon shares his top 5 tips
    //   </h3>
    //   <div>
    //     {howToChoose.map((item, index) => (
    //       <div key={index} className="how-choose-item">
    //         <p className="how-choose-item__index">{index + 1}</p>
    //         <p className="how-choose-item__title">{item.title}</p>
    //         <p className="how-choose-item__text">{item.text}</p>
    //       </div>
    //     ))}
    //   </div>
    //   {show && (
    //     <Link
    //       to="less-text"
    //       smooth={true}
    //       duration={900}
    //       className="toggle-text-more"
    //       onClick={() => {
    //         isToggle();
    //       }}>
    //       Show less
    //     </Link>
    //   )}
    // </div>
  );
};

export default HowDoChoose;

import React from "react";

import "./OurCriteria.scss";

import { ourCriteriaDa } from "../../data";

export const OurCriteriaDa = () => {
  return (
    <div className="container">
      <h2 className="green-text">VORES ONLINE CASINO ANMELDELSESKRITERIER</h2>
      <div className="our-criteria">
        {ourCriteriaDa.map((elem, index) => (
          <div key={index} className="our-criteria-container">
            <div key={index} className="our-criteria__item">
              <img className="our-criteria__item-images" src={elem.images} alt="images" />
              <h3 className="our-criteria__item-title">{elem.title}</h3>
              <p>{elem.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { offerAlphabeticalSorting } from "../../utils";
import { navHeader } from "../../data";
// import { useWindowSize } from "../../utils/useWindowSize";
import Arrow from "./svgs/Arrow";

import "./Header.scss";

const MenuItem = ({ name, link, children, closeMenu, isActive }) => {
  const [isOpenChildren, setIsOpenChildren] = useState(false);

  const handleOpenChildren = () => {
    setIsOpenChildren(!isOpenChildren);
  };

  const handleClick = () => {
    if (children && children.length === 0) {
      closeMenu();
    }
    handleOpenChildren();
  };

  return (
    <li>
      <Link to={children && children.length === 0 ? link : "#"} onClick={handleClick}>
        {name} {children && children.length !== 0 && <Arrow />}
      </Link>
      {children && children.length !== 0 && (
        <ul className={isOpenChildren ? `open` : ``}>
          {offerAlphabeticalSorting(children).map(item => (
            <li key={item.name}>
              <Link to={item.link} className={isActive(item.link)} onClick={closeMenu}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

const Menu = ({ pathname, setisNavFixed }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  React.useEffect(() => window.scrollTo(0, 0), [pathname]);

  const toggleMenu = () => {
    setIsMenuOpen(isMenuOpen => !isMenuOpen);
    setisNavFixed(isMenuOpen => !isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
    setisNavFixed(false);
  };
  const isActive = to => (to === pathname ? "active" : "");

  return (
    <>
      <button
        type="button"
        className={isMenuOpen ? "button-open-menu open" : "button-open-menu"}
        onClick={toggleMenu}>
        <span />
        <span />
        <span />
      </button>
      <ul className={isMenuOpen ? "menu open" : "menu"}>
        {navHeader.map(({ name, link, children }) => (
          <MenuItem
            name={name}
            link={link}
            children={children}
            key={link}
            closeMenu={closeMenu}
            isActive={isActive}
          />
        ))}
      </ul>
    </>
  );
};

const HeaderComponent = () => {
  const pathname = useHistory().location.pathname;

  const [isNavFixed, setisNavFixed] = useState(false);

  return (
    <div className="header">
      {pathname !== "/promo/lvlup" && <div className="black-circle" />}
      {pathname !== "/promo/lvlup" && <div className="green-circle" />}

      <div className="header__row">
        <div className="header__logo">
          <a href="/">
            <img src="/logotype.png" alt="logo" />
          </a>
        </div>
        <div className={isNavFixed ? "header__nav nav__open" : "header__nav"}>
          <Menu pathname={pathname} setisNavFixed={setisNavFixed} />
        </div>
      </div>
    </div>
  );
};

export const Header = HeaderComponent;

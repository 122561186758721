const Reliability = "./images/reliability.svg";
const Games = "./images/games.svg";
const Bonuses = "./images/bonuses.svg";
const Customer = "./images/customer.svg";
const Banking = "./images/banking.svg";
const Mobile = "./images/mobile.svg";

export const ourCriteria = [
  {
    images: Reliability,
    title: "Reliability",
    text:
      "It is very important that your funds and private data are safe while gambling online. During our 6-step   casino verification we check for legal, fair licensing, regulation and reliable data protection.",
  },
  {
    images: Games,
    title: "Games & Software",
    text:
      "It is very important that your funds and private data are safe while gambling online. During our 6-step   casino verification we check for legal, fair licensing, regulation and reliable data protection.",
  },
  {
    images: Bonuses,
    title: "Bonuses & Promotions",
    text:
      "Generous welcome bonuses give you an opportunity to significantly boost your gaming experience. They should be easy to claim with no excessive complexities and bureaucracy. And of course each casino must have a VIP program for royal players.",
  },
  {
    images: Customer,
    title: "Customer Support",
    text:
      "A good casino must strive to build their client side the way a customer would never have any questions while using it. But at the same time a good casino must have a 24/7 customer support service ready to answer any clients’ questions in case they have them via all channels such as email, live chat or telephone.",
  },
  {
    images: Banking,
    title: "Banking",
    text:
      "A wide choice of trusted banking options is crucial for both depositing and withdrawing processes to be safe and consume minimum time and efforts.",
  },
  {
    images: Mobile,
    title: "Mobile",
    text:
      "With the popularity of mobile gaming rising in recent years, online casinos must be ready to operate on all popular mobile devices.",
  },
];

export const ourCriteriaDa = [
  {
    images: Reliability,
    title: "Pålidelighed",
    text:
      "Det er meget vigtigt, at dine midler og private data er sikre, mens du spiller online. Under vores 6-trins casinoverifikation kontrollerer vi for lovlig, fair licensering, regulering og pålidelig databeskyttelse.",
  },
  {
    images: Games,
    title: "Spil og software",
    text:
      "Det er meget vigtigt, at dine midler og private data er sikre, mens du spiller online. Under vores 6-trins casinoverifikation kontrollerer vi for lovlig, fair licensering, regulering og pålidelig databeskyttelse.",
  },
  {
    images: Bonuses,
    title: "Bonusser og kampagner",
    text:
      "Generøse velkomstbonuser giver dig mulighed for at øge din spiloplevelse markant. De skal være lette at kræve uden overdreven kompleksitet og bureaukrati. Og selvfølgelig skal hvert casino have et VIP-program til kongelige spillere.",
  },
  {
    images: Customer,
    title: "Kunde support",
    text:
      "Et godt casino skal stræbe efter at opbygge deres klientside, sådan som en kunde aldrig ville have nogen spørgsmål, mens han bruger det. Men samtidig skal et godt casino have en 24/7 kundesupport klar til at besvare eventuelle kunders spørgsmål, hvis de har dem via alle kanaler såsom e-mail, live chat eller telefon.",
  },
  {
    images: Banking,
    title: "Bankvirksomhed",
    text:
      "Et bredt udvalg af pålidelige bankmuligheder er afgørende for, at både deponering og udtrækningsprocesser er sikre og bruger minimalt med tid og kræfter.",
  },
  {
    images: Mobile,
    title: "Mobil",
    text:
      "Da populariteten af mobilspil er steget i de senere år, skal online casinoer være klar til at fungere på alle populære mobile enheder.",
  },
];

import React from "react";

import TrackingLink from "../TrackingLink";
import { Link } from "react-router-dom";

import { useWindowSize } from "../../utils/useWindowSize";
import "./MainOffer.scss";

export const MainOffer = ({ offer, top }) => {
  const width = useWindowSize().width;

  return (
    <div className="container">
      <div className={top || width < 580 ? "top-offer-block" : "top-offer-block mt-80"}>
        <div className="content-container">
          {top && <h2 className="top-offer-block__title">TOP RATED DANISH ONLINE CASINO</h2>}
          <div className={top ? "top-offer-block__logos" : "top-offer-block__logos mt-8"}>
            <div className="top-offer-block__logos__img">
              <img src={offer.logo} alt="logo" />
              {top && (
                <img
                  src="/images/crown.png"
                  alt="crown"
                  className="top-offer-block__logos__img-crown"
                />
              )}
            </div>
            <div
              className={
                top
                  ? "top-offer-block__logos__description"
                  : "top-offer-block__logos__description top-offer-block__logos__description-preline"
              }>
              {offer.description}
              <span>Welcome bonus</span>
            </div>
          </div>
          <div className="offers-tiles__item__info__advantage">
            {top ? (
              <ol className="top-ol">
                {offer.profs.map((elem, index) => {
                  return width > 768 ? (
                    <li key={index}>{elem}</li>
                  ) : (
                    index < 2 && <li key={index}>{elem}</li>
                  );
                })}
              </ol>
            ) : (
              <ol className="top-ol">
                {offer.profs.map((elem, index) => {
                  return <li key={index}>{elem}</li>;
                })}
              </ol>
            )}
            <div className="top-offer-block__button-mobile">
              {top && (
                <TrackingLink href={offer.link} className="button-1">
                  go to site
                </TrackingLink>
              )}

              {top && (
                <Link to={offer.review} className="button-2 mt-8">
                  read review
                </Link>
              )}
            </div>
          </div>
          {!top && (
            <div className="top-offer-block__payment-system">
              <div className="payment-system__row payment-review">
                {offer.paymentSystem.map((item, index) => (
                  <div key={index} className="payment-system__col">
                    <img src={item} alt="paymentSystem" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="top-offer-block__button">
          <TrackingLink href={offer.link} className="button-1 mr-15">
            go to site
          </TrackingLink>
          {top && (
            <Link to={offer.review} className="button-2">
              read review
            </Link>
          )}
        </div>
        {!top && width < 768 ? (
          <div className="top-offer-block__button-center">
            <TrackingLink href={offer.link} className="button-1 mr-15">
              go to site
            </TrackingLink>
          </div>
        ) : null}
      </div>
    </div>
  );
};

import React from "react";

import { ProsCons } from "../../../components";

export const FortuneClock = () => (
  <div className="container">
    <div className="text-review-block">
      <h2>FortuneClock Casino - online casino review</h2>
      <p>
        Fortune Clock Casino was founded in 2020 and it is officially licensed by Curacao
        8048/JAZ. It operates in 24 countries such as: Poland, Sweden, South Africa, Germany,
        Austria, Switzerland, Belgium, Argentina, Mexico, Peru, Chile, Uruguay, Turkey, Norway,
        Finland, Estonia, Latvia, Lithuania, Slovakia, Serbia, Rumania, Bulgaria, Romania ,
        Kazakhstan. The Casino is restricted in India, Hungary, Slovakia, United Kingdom,
        Curacao, France, the USA, Netherlands, Ukraine, Italy, Spain.
      </p>
      <h3>Procs and cons</h3>
      <ProsCons
        pros={[
          "Home to leading software providers",
          "Live chat is open 24/7",
          "Mobile friendly design",
          "Games proven fair",
        ]}
        cons={["There are no video poker games", "36-Hour pending times"]}
      />
      <p>
        The Casino offers several game types like Baccarat, Bingo, Blackjack, Craps, Keno,
        Roulette, Scratch Cards, Slots, Table Games, Video Poker and convenient playing methods
        introduced with Instant Play, Live Dealer, Mobile, Flash, and Mobile Web modes.
      </p>
      <p>
        All the games are available in different languages – in English, Russian, German,
        Spanish, Polish, Turkish, Portuguese, Finnish, Norwegian, Czech, Swedish.
      </p>
      <p>
        When you open Australian online casino reviews, you will be impressed with the
        dark-colored design with some sci-fi or futuristic elements. The navigation is easy to
        use and quick. And the sign-in process is very simple.
      </p>
      <h3>BONUSES</h3>
      <p>Fortune Clock Casino offers different and pleasant bonuses for the clients.</p>
      <p>
        Super pleasant Welcome package: 225% and 225 free spins for the first replenishment
        Fortune Clock Casino gives 50 free spins no deposit on Starburst to all new clients,
        All you need – is to sign up a new account and get automatically 50 free spins on
        Starburst with no deposit requirement. There are also some offers, the real gambler can
        not ignore!
      </p>
      <h3>100% AND 100 SPINS IN STARBURST</h3>
      <p>
        Casino offers a surprise, all you need is the deposit from 20 EUR. After that you get a
        100% bonus for your replenishment and 20 free spins in the popular Starburst slot
        machine! And also 4 days of gifts with 20 free spins in the legendary slot a day! The
        wager for the bonus is 40x. The offer is valid for 120 hours.
      </p>
      <h3>75% AND 75 SPINS IN BOOK OF DEAD</h3>
      <p>
        To activate the gift from Fortune Clock Casino, make a deposit from 70 EUR and get a
        winning set – a 75% bonus and 15 free spins in the Book of Dead slot! You can get 15
        free spins in the Book of Dead over the next 4 days! The wager for the gift is 40x. The
        offer is valid for 120 hours.
      </p>
      <h3>50% AND 50 SPINS IN GONZO’S QUEST!</h3>
      <p>
        Amazing gifts are waiting for every player here. To activate them, deposit to your
        account should be from 135 EUR and you will get 10 free spins in Gonzo’s Quest and a
        50% bonus to replenishment right after the payment! Besides, you will get 10 free spins
        for 4 more days in the popular Gonzo’s Quest slot! The wager for the bonus is 40x. The
        offer is valid for 120 hours.
      </p>
      <p>
        Fortune Clock Casino works with a great number of software suppliers such as NetEnt,
        Play`N`Go, Novomatic, Wazdan, BTG(Bigtimegaming), Microgaming, PROGaming, QuickSpin,
        AMATIC, BetSoft, Evolution Gaming, Thunderkick, HABANERO, AuthenticGaming, LuckyStreak,
        TomHorn Gaming, Endorphina, Igrosoft, Belatra, PlaySon, Boongo, Ezugi, AugustGaming,
        IronDog Studio, ELK Srudio, 1×2 Gaming, CHANCE INTERACTIVE, Boomerang Studio, Bet2Tech,
        Blueprint Gaming, Betixon, Red Tiger, Felix Gaming, Evoplay, Platipus, GamesLab,
        Lightning BOX, Mobilots, Gamefish global, VIVO, Game Art, FUGASO, Gamshy, BBG,
        WORLDMATCH, APOLLO Games, Pariplay, NOLIMIT City, SPLITROCK Gaming, PUSH GAMING. Thanks
        to all of them, our abilities and your pleasure are unlimited.
      </p>
      <p>
        Casino Fortune Clock offers its customers games from 54 providers, which can be called
        a very large number, especially for a fresh institution. Here you can also bet on
        sporting events – both real and virtual.
      </p>
      <p>
        There are 2300 exciting and well-made brite games, which will make you feel like you
        are in a real casino! The catalog is divided into the following categories:
      </p>
      <div className="ml-30">
        <span>Seasonal – games suitable for winter, spring, summer or autumn.</span>
        <br />
        <span>Popular – the most popular entertainment among users.</span>
        <br />
        <span>New – new in the world of gambling.</span>
        <br />
        <span>Slots – classic and latest releases in slot machines.</span> <br />
        <span>
          Board games are traditional games such as roulette, baccarat, blackjack and so on.
        </span>
        <br />
      </div>
      <p>
        After you register, you can also add your favorite entertainment to a separate category
        – Favorites.
      </p>
      <p>
        Fortune Clock Casino is expanding its field of activity and offers players not only
        classic gambling, but also sports and eSports bets. You need to choose a sport, as well
        as decide on a favorite to predict the outcome of the competition. If you guess the
        outcome of the game, the winnings will be on your account.
      </p>
      <p>
        It is noteworthy that, in accordance with the latest global trends in the world of
        betting shops, FORTUNE CLOCK Casino can bet on eSports events.
      </p>
      <p>Namely, on:</p>
      <div className="ml-30">
        <span>Counter strike</span>
        <br />
        <span>Starcraft 2</span>
        <br />
        <span>DOTA 2</span>
        <br />
        <span>League of Legends</span>
        <br />
        <span>and other games, depending on the time of the championships.</span>
      </div>
      <h3>MOBILE VERSION</h3>
      <p>
        Another popular way to visit Fortune Clock Casino is to open the website with your
        smartphone or a tablet with internet access. The portal comes off in an Internet
        browser, so here will be no need to download additional apps. The graphics’ quality is
        also very high and the machines work in test and standard modes.
      </p>
      <h3>FORTUNE CLOCK CASINO’S DEPOSITS AND WITHDRAWAL METHODS</h3>
      <p>
        No deposit bonus: 50 free spins in the Starburst slot, just bet 0,01 EUR wager – 40x,
        maximum bet – 2 EUR and the maximum withdrawal isl 4 EUR. The maximum deposit is 10 EUR
        and the minimum withdrawal is 50 EUR The withdrawal is very convenient and can be
        executed in TRY, PLN, EUR, USD, RUB, ARS, PEN, MXN, CLP, ZAR, NOK, BRL, CAD, AUD, CHF,
        CZK. <br />
        The payment systems are also very convenient for players. You can use these systems to
        put money on your account : Visa, ecoPayz, Qiwi, YandexMoney, MegaFon, MTS, Tele2,
        Beeline, Payeer, Perfect Money. <br />
        And it is easy and quickly to get you withdrawal with Visa, ecoPayz, Qiwi, YandexMoney,
        MegaFon, MTS, Tele2, Beeline, Payeer, Perfect Money, WebMoney. <br />
        You can withdraw – 2’000 EUR daily, 10’000 EUR weekly and 40’000 EUR monthly, which
        sounds very tempting!
      </p>
      <h3>Conclusion</h3>
      <p>
        The Fortune Clock Casino is a good example of a modern online Casino, which can be
        pleasant and convenient both for advanced players and new users. The great advantages
        of this Casino are the aLive Chat and Live Support. You can use both when you have some
        questions or want to talk to other players. Besides, the Casino has not only online
        casino games but also so popular now hot sports betting options.
      </p>
      <p>
        And, of course, the bonuses and surprises can not leave any player indifferent to this
        Casino.
      </p>
    </div>
  </div>
);

import React from "react";

function TrackingLink({ href, className, children }) {
  const [link, setLink] = React.useState(href);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (localStorage.getItem("tid")) {
        setLink(link => link + "?tid=" + localStorage.getItem("tid"));

        clearInterval(intervalId);
      }
    }, 200);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <a href={link} className={className} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

export default TrackingLink;

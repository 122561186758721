import React from "react";
import SlideToggle from "react-slide-toggle";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { LvlUpContent } from "../../../components";
import TrackingLink from "../../../components/TrackingLink";

import "./LvlUp.scss";

const LvlUp = () => {
  const [copied, setCopied] = React.useState(false);

  const copyText = () => {
    setCopied(true);

    setTimeout(() => setCopied(false), 500);
  };

  return (
    <React.Fragment>
      <div className="wrapper">
        <div className="lvl-container">
          <TrackingLink className="logo" href="/go/lvlup">
            <img src="/logotype.png" alt="logo" />
          </TrackingLink>
          <div className="center">
            <div className="bonus">
              <div className="bonus__1 bold-text">Welcome Package</div>
              <div className="bonus__2 bold-text">
                100% UP TO €600 <br />+ 140 FS
              </div>
            </div>
            <div className="get-code">
              <div className="get-code__1">
                <div className="get-code__1__1">
                  <CopyToClipboard text="WOLF" onCopy={copyText}>
                    <span className="bold-text">Copy Code</span>
                  </CopyToClipboard>
                </div>
                <div className="get-code__1__2">
                  <CopyToClipboard text="WOLF" onCopy={copyText}>
                    <span className="bold-text">WOLF</span>
                  </CopyToClipboard>
                  <p
                    style={copied === true ? { display: "block" } : { display: "none" }}
                    className="copied">
                    Copied!
                  </p>
                </div>
              </div>
              <div className="get-code__2">
                <TrackingLink className="button-claim bold-text" href="/go/lvlup">
                  Claim Now
                </TrackingLink>
              </div>
              <div className="wolf-img"></div>
              <div className="get-code__3">
                <SlideToggle
                  duration={800}
                  collapsed
                  render={({ toggle, setCollapsibleElement, progress }) => (
                    <div>
                      <div className="button-terms" onClick={toggle} id="btn-open-popup">
                        Terms and Conditions
                      </div>
                      <div
                        ref={setCollapsibleElement}
                        style={{
                          background: `rgba(37, 170, 214, ${progress})`,
                        }}
                        className="popup__terms">
                        <div
                          style={{
                            padding: "0 1rem 10px",
                            transform: `translateY(${Math.round(15 * (0 + progress))}px)`,
                            borderRadius: " 8px",
                          }}>
                          Welcome package is valid only for new players for 14 days upon
                          registration and consists of 4 bonuses.
                          <ul
                            style={{
                              padding: "18px",
                            }}>
                            <li>
                              WOLF: 100% up to 100 AUD/CAD/EUR/NZD/USD + 100 FS, WG x40, valid
                              on the 1st deposit.
                            </li>
                            <li>
                              WOLF2: 50% up to 100 AUD/CAD/EUR/NZD/USD + 20 FS, WG x40, valid
                              on the 2nd deposit.
                            </li>
                            <li>
                              WOLF3: 50% up to 200 AUD/CAD/EUR/NZD/USD, WG x40, valid on the
                              3rd deposit.
                            </li>
                            <li>
                              WOLF4: 100% up to 200 AUD/CAD/EUR/NZD/USD + 20 FS, WG x40, valid
                              on the 4th deposit.
                            </li>
                          </ul>
                          Min dep: 20 AUD/CAD/EUR/NZD/USD, 200 NOK, 1500 RUB, 90 PLN, 2000 JPY,
                          9000 KZT, 300 ZAR. Max bet during the wagering: 5 EUR/USD, 6.5
                          CAD/AUD/NZD, 50 NOK, 350 RUB, 20 PLN, 600 JPY, 2,100 KZT. All Free
                          Spins are valid for 24h after the deposit, WG winnings x40.
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="wolf"></div>
        <div className="moon"></div>
      </div>
      <LvlUpContent />
    </React.Fragment>
  );
};

export default LvlUp;

import {
  WooCasino,
  TrueFlip,
  CookieCasino,
  Slottica,
  FortuneClock,
} from "../pages/reviews/offers";

// logos
import WooCasinoLogo from "./images/logos/woocasino.png";
import TrueFlipLogo from "./images/logos/trueflip.png";
import CookieCasinoLogo from "./images/logos/cookie-casino.png";
import SlotticaLogo from "./images/logos/slottica.png";
import FortuneclockLogo from "./images/logos/fortuneclock.png";
import LvUpLogo from "./images/logos/logo.svg";

//paymentSystem
import visa from "./images/payloadSystem/visa-logo.png";
import bitcoin from "./images/payloadSystem/bitcoin-logo.png";
import skrill from "./images/payloadSystem/logo-skrill.png";
import neteller from "./images/payloadSystem/neteller_logo.png";
import paySafeCard from "./images/payloadSystem/paysafecard-logo.png";
import mastercard from "./images/payloadSystem/master-card.png";

const scores = [
  9.7,
  9.5,
  9.5,
  9.4,
  9.4,
  9.2,
  9.0,
  9.0,
  8.7,
  8.6,
  8.6,
  8.5,
  8.2,
  8.1,
  8.1,
  8.0,
];

const offer = [
  // 1
  {
    name: "WooCasino",
    logo: WooCasinoLogo,
    ratings: [
      {
        name: "Game Variety",
        score: 6.5,
      },
      {
        name: "Game Quality",
        score: 7.5,
      },
      {
        name: "User Expiries",
        score: 9.5,
      },
      {
        name: "Bonuses",
        score: 6,
      },
      {
        name: "Slots",
        score: 8,
      },
    ],
    votes: 2379,
    paymentSystem: [paySafeCard, neteller, skrill, bitcoin, visa],
    description: "€100 + 150 Free Spins",
    descriptionSlider: "€100 \n + 150 Free Spins",
    Component: WooCasino,
    review: "/woocasino",
    link: "/go/woo-casino",
    profs: [
      "High roller program. Special bonuses",
      "Tournaments with potential wins up to €1000",
      "Weekend Reload : 50% bonus up to €100 + 60 Free Spins",
      "First Deposit Bonus : a 100% bonus up to €100 + 150 Free Spins",
      "Second Deposit Bonus : 50% bonus up to €100 + 50 Free Spins",
    ],
    backgroundColor: "transparent",
  },
  {
    name: "Slottica",
    logo: SlotticaLogo,
    ratings: [
      {
        name: "Game Variety",
        score: 6.8,
      },
      {
        name: "Game Quality",
        score: 6.5,
      },
      {
        name: "User Expiries",
        score: 6.5,
      },
      {
        name: "Bonuses",
        score: 7,
      },
      {
        name: "Slots",
        score: 9,
      },
    ],
    votes: 2379,
    paymentSystem: [mastercard, paySafeCard, neteller, skrill, visa],
    description: "200% + 25 Free Spins",
    descriptionSlider: "200% \n + 25 Free Spins",
    Component: Slottica,
    review: "/slottica",
    link: "/go/slottica",
    profs: [
      "Top performing software providers",
      "Live chat is available",
      "Mobile friendly design",
      "Easy to use lobby with multiple filters",
    ],
    backgroundColor: "transparent",
  },
  {
    name: "Cookie Casino",
    logo: CookieCasinoLogo,
    ratings: [
      {
        name: "Game Variety",
        score: 6.5,
      },
      {
        name: "Game Quality",
        score: 7.5,
      },
      {
        name: "User Expiries",
        score: 9.5,
      },
      {
        name: "Bonuses",
        score: 6,
      },
      {
        name: "Slots",
        score: 8,
      },
    ],
    votes: 2048,
    paymentSystem: [paySafeCard, skrill, visa, mastercard],
    description: "100% Up To €100 \n + 120 Free Spins",
    descriptionSlider: "100% Up To €100 \n + 120 Free Spins",
    Component: CookieCasino,
    review: "/cookie-casino",
    link: "/go/cookie-casino",
    profs: [
      "Home to leading software developers",
      "Withdrawals within 24 hours one processed",
      "User friendly interface",
      "Mobile friendly design",
    ],
    backgroundColor: "#008080",
  },
  {
    name: "Fortuneclock",
    logo: FortuneclockLogo,
    ratings: [
      {
        name: "Game Variety",
        score: 6.8,
      },
      {
        name: "Game Quality",
        score: 6.5,
      },
      {
        name: "User Expiries",
        score: 6.5,
      },
      {
        name: "Bonuses",
        score: 7,
      },
      {
        name: "Slots",
        score: 9,
      },
    ],
    votes: 2379,
    paymentSystem: [mastercard, visa],
    description: "225% + 225 Free Spins",
    descriptionSlider: "225% \n + 225 Free Spins",
    Component: FortuneClock,
    review: "/fortuneclock",
    link: "/go/fortuneclock",
    profs: [
      "Home to leading software providers",
      "Live chat is open 24/7",
      "Mobile friendly design",
      "Games proven fair",
    ],
    backgroundColor: "transparent",
  },
  {
    name: "TrueFlip",
    logo: TrueFlipLogo,
    ratings: [
      {
        name: "Game Variety",
        score: 6.5,
      },
      {
        name: "Game Quality",
        score: 7.5,
      },
      {
        name: "User Expiries",
        score: 9.5,
      },
      {
        name: "Bonuses",
        score: 6,
      },
      {
        name: "Slots",
        score: 8,
      },
    ],
    votes: 2457,
    paymentSystem: [bitcoin, visa],
    description: "Up To €300 + 50 Free Spins",
    descriptionSlider: "Up To €300 \n + 50 Free Spins",
    Component: TrueFlip,
    review: "/trueflip",
    link: "/go/trueflip",
    profs: [
      "VIP Bonus: 100% on their deposits in this fantastic loyalty feature up to €1000",
      "Chain’s Bonus: 50% on top of the player’s deposit up to of €100",
      "Henry’s Bonus: special 75% deposit bonus up to €250",
      "Welcome Pack: 150% Welcome bonus up to €300",
    ],
    backgroundColor: "#faebd7",
  },
  {
    name: "lvlup",
    logo: LvUpLogo,
    ratings: [
      {
        name: "Game Variety",
        score: 6.5,
      },
      {
        name: "Game Quality",
        score: 7.5,
      },
      {
        name: "User Expiries",
        score: 9.5,
      },
      {
        name: "Bonuses",
        score: 6,
      },
      {
        name: "Slots",
        score: 8,
      },
    ],
    votes: 2457,
    paymentSystem: [paySafeCard, neteller, skrill, bitcoin, visa],
    description: "100% UP TO €600 + 140 FS",
    descriptionSlider: "100% UP TO €600 + 140 FS",
    // Component: TrueFlip,
    review: "/promo/lvlup",
    link: "/go/lvlup",
    profs: [
      "Lots of top performing software providers",
      "Live chat is open 24/7",
      "Mobile friendly design",
      "User-friendly filters in the casino lobby",
    ],
    backgroundColor: "#faebd7",
  },

  // {
  //   name: "EU Slots Casino",
  //   logo: EuslotLogo,
  //   ratings: [
  //     {
  //       name: "Game Variety",
  //       score: 6.5,
  //     },
  //     {
  //       name: "Game Quality",
  //       score: 7.5,
  //     },
  //     {
  //       name: "User Expiries",
  //       score: 9.5,
  //     },
  //     {
  //       name: "Bonuses",
  //       score: 6,
  //     },
  //     {
  //       name: "Slots",
  //       score: 8,
  //     },
  //   ],
  //   votes: 2048,
  //   paymentSystem: [paySafeCard, neteller, skrill, visa, mastercard],
  //   description: "100% Up To €100 \n + 100 Free Spins",
  //   descriptionSlider: "100% Up To €100 \n + 100 Free Spins",
  //   Component: Euslot,
  //   review: "/euslots",
  //   link: "/go/euslot",
  //   profs: [
  //     "Live chat is open 24/7",
  //     "Top tier software providers",
  //     "Mobile-friendly design",
  //     "User-friendly interface",
  //   ],
  //   backgroundColor: "#008080",
  // },
  // {
  //   name: "Dunder casino",
  //   logo: DunderCasinoLogo,
  //   ratings: [
  //     {
  //       name: "Game Variety",
  //       score: 6,
  //     },
  //     {
  //       name: "Game Quality",
  //       score: 8.5,
  //     },
  //     {
  //       name: "User Expiries",
  //       score: 8.5,
  //     },
  //     {
  //       name: "Bonuses",
  //       score: 6.5,
  //     },
  //     {
  //       name: "Slots",
  //       score: 8.5,
  //     },
  //   ],
  //   votes: 2048,
  //   paymentSystem: [paySafeCard, visa, mastercard],
  //   description: "Get €100 + 120 Free Spins",
  //   descriptionSlider: "Get €100 \n + 120 Free Spins",
  //   Component: Dunder,
  //   review: "/dunder-casino",
  //   link: "/go/dunder",
  //   profs: [
  //     "Spin lots of slots from big software providers like Microgaming and Betsoft",
  //     "Play a top choice of live casino games powered by Evolution Gaming",
  //     "Enjoy low playthrough requirements on bonus spins",
  //   ],
  //   backgroundColor: "#008080",
  // },
  // {
  //   name: "Betamo",
  //   logo: BetamoLogo,
  //   ratings: [
  //     {
  //       name: "Game Variety",
  //       score: 6.5,
  //     },
  //     {
  //       name: "Game Quality",
  //       score: 7.5,
  //     },
  //     {
  //       name: "User Expiries",
  //       score: 9.5,
  //     },
  //     {
  //       name: "Bonuses",
  //       score: 6,
  //     },
  //     {
  //       name: "Slots",
  //       score: 8,
  //     },
  //   ],
  //   votes: 2865,
  //   paymentSystem: [paySafeCard, neteller, skrill, visa],
  //   description: "€150 + 100 Free Spins",
  //   descriptionSlider: "€150 \n + 100 Free Spins",
  //   Component: Betamo,
  //   review: "/betamo",
  //   link: "/go/betamo-casino",
  //   profs: [
  //     "VIP Program for Royal users",
  //     "Tournaments with every day Jackpots up to €700",
  //     "Friday Bonus: 55% bonus up to €300",
  //     "Welcome Bonus: 100% Welcome bonus up to €100",
  //     "Get Free Spins everyday",
  //   ],
  //   backgroundColor: "transparent",
  // },
  // {
  //   name: "Loki casino",
  //   logo: LokiCasinoLogo,
  //   ratings: [
  //     {
  //       name: "Game Variety",
  //       score: 8.5,
  //     },
  //     {
  //       name: "Game Quality",
  //       score: 8.5,
  //     },
  //     {
  //       name: "User Expiries",
  //       score: 9,
  //     },
  //     {
  //       name: "Bonuses",
  //       score: 6.5,
  //     },
  //     {
  //       name: "Slots",
  //       score: 7.5,
  //     },
  //   ],
  //   votes: 2048,
  //   paymentSystem: [paySafeCard, neteller, skrill, visa, mastercard],
  //   description: "100% Up To $600",
  //   descriptionSlider: "100% Up To $600",
  //   Component: LokiCasino,
  //   review: "/loki-casino",
  //   link: "/go/loki-casino",
  //   profs: [
  //     "0-24 withdrawal time frame",
  //     "Mobile-friendly",
  //     "Top-tier software providers",
  //     "Live chat open 24/7",
  //   ],
  //   backgroundColor: "#008080",
  // },
  // {
  //   name: "GunsBet",
  //   logo: GunsBetLogo,
  //   ratings: [
  //     {
  //       name: "Game Variety",
  //       score: 6.5,
  //     },
  //     {
  //       name: "Game Quality",
  //       score: 7.5,
  //     },
  //     {
  //       name: "User Expiries",
  //       score: 9.5,
  //     },
  //     {
  //       name: "Bonuses",
  //       score: 6,
  //     },
  //     {
  //       name: "Slots",
  //       score: 8,
  //     },
  //   ],
  //   votes: 2048,
  //   paymentSystem: [paySafeCard, neteller, skrill, visa],
  //   description: "Up To €100 \n + 100 Free Spins",
  //   descriptionSlider: "Up to €100 \n + 100 Free Spins",
  //   Component: GunsBet,
  //   review: "/gunsbet",
  //   link: "/go/gunsbet",
  //   profs: [
  //     "VIP Program for Royal users",
  //     "Tournaments with every day Jackpots up to €700",
  //     "Friday Bonus: 55% bonus up to €300",
  //     "Welcome Bonus: 100% Welcome bonus up to €100",
  //     "Get Free Spins everyday",
  //   ],
  //   backgroundColor: "#008080",
  // },
  // // // 3
  // {
  //   name: "Casinochan",
  //   logo: CasinochanLogo,
  //   ratings: [
  //     {
  //       name: "Game Variety",
  //       score: 6.5,
  //     },
  //     {
  //       name: "Game Quality",
  //       score: 7.5,
  //     },
  //     {
  //       name: "User Expiries",
  //       score: 9.5,
  //     },
  //     {
  //       name: "Bonuses",
  //       score: 6,
  //     },
  //     {
  //       name: "Slots",
  //       score: 8,
  //     },
  //   ],
  //   votes: 2323,
  //   paymentSystem: [paySafeCard, neteller, skrill, bitcoin, visa],
  //   description: "€100 + 30 Free Spins",
  //   descriptionSlider: "€100 \n + 30 Free Spins",
  //   Component: Casinochan,
  //   review: "/casinochan",
  //   link: "/go/casinochan",
  //   profs: [
  //     "100 Free Spins for a Thursday deposit",
  //     "50% Monday Reload Bonus",
  //     "First Deposit Bonus : a 100% bonus from your 1st deposit up to €100 + 30 Free Spins",
  //     "Second Deposit Bonus : 50% bonus €100 + 30 Free Spins",
  //     "Third Deposit Bonus : 50% bonus €100 + 30 Free Spins",
  //   ],
  //   backgroundColor: "#008080",
  // },
  // // //4

  // // //5
  // {
  //   name: "IrishLuck",
  //   logo: IrishLuckLogo,
  //   ratings: [
  //     {
  //       name: "Game Variety",
  //       score: 6.5,
  //     },
  //     {
  //       name: "Game Quality",
  //       score: 7.5,
  //     },
  //     {
  //       name: "User Expiries",
  //       score: 9.5,
  //     },
  //     {
  //       name: "Bonuses",
  //       score: 6,
  //     },
  //     {
  //       name: "Slots",
  //       score: 8,
  //     },
  //   ],
  //   votes: 2048,
  //   paymentSystem: [paySafeCard, neteller, skrill, visa, mastercard],
  //   description: "Up to 295% Slots Match",
  //   descriptionSlider: "Up to 295% Slots Match",
  //   Component: IrishLuck,
  //   review: "/irish-luck",
  //   link: "/go/irish-luck",
  //   profs: [
  //     "Wide range of Games categories",
  //     "Mobile casino",
  //     "Hot Weekly and Monthly Bonuses",
  //     "Trusted casino software",
  //     "Sweet Welcome Bonus of 235%",
  //   ],
  //   backgroundColor: "#008080",
  // },
  // // // 6

  // {
  //   name: "Playamo",
  //   logo: PlayamoLogo,
  //   ratings: [
  //     {
  //       name: "Game Variety",
  //       score: 6.5,
  //     },
  //     {
  //       name: "Game Quality",
  //       score: 7.5,
  //     },
  //     {
  //       name: "User Expiries",
  //       score: 9.5,
  //     },
  //     {
  //       name: "Bonuses",
  //       score: 6,
  //     },
  //     {
  //       name: "Slots",
  //       score: 8,
  //     },
  //   ],
  //   votes: 2527,
  //   paymentSystem: [paySafeCard, neteller, skrill, bitcoin, visa],
  //   description: "€100 + 100 Free Spins",
  //   descriptionSlider: "€100 \n + 100 Free Spins",
  //   Component: Playamo,
  //   review: "/playamo",
  //   link: "/go/playamo",
  //   profs: [
  //     "High Roller Bonus 50% up to €2,000",
  //     "Friday Reload - 50% bonus up to €250",
  //     "Monday Free Spins up to 100",
  //     "First Deposit Bonus : will double your 1st deposit amount up to €100 + 100 Free Spins",
  //     "Second Deposit Bonus : up to €200 + 50 Free Spins",
  //   ],
  //   backgroundColor: "#faebd7",
  // },
  // // //7
  // {
  //   name: "Nomini",
  //   logo: NominiLogo,
  //   ratings: [
  //     {
  //       name: "Game Variety",
  //       score: 6.5,
  //     },
  //     {
  //       name: "Game Quality",
  //       score: 7.5,
  //     },
  //     {
  //       name: "User Expiries",
  //       score: 9.5,
  //     },
  //     {
  //       name: "Bonuses",
  //       score: 6,
  //     },
  //     {
  //       name: "Slots",
  //       score: 8,
  //     },
  //   ],
  //   votes: 2249,
  //   paymentSystem: [paySafeCard, neteller, skrill, bitcoin, visa],
  //   description: "Up To €1000 in 3 Bonuses \n + 100 Free Spins",
  //   descriptionSlider: "Up To €1000 in 3 Bonuses \n + 100 Free Spins",
  //   Component: Nomini,
  //   review: "/nomini",
  //   link: "/go/nomini",
  //   profs: [
  //     "Tournaments with potential wins up to €1000",
  //     "15% Live Cashback bonus up to €3000",
  //     "Weekend Reload Bonus : up to €700 + 50 Free Spins",
  //     "Different First Deposit Bonuses : a 50% bonus up to €1000 + 100 Free Spins",
  //     "Drops & Wins Bonus up to €4500",
  //   ],
  //   backgroundColor: "#faebd7",
  // },
  // // //8
  // {
  //   name: "GoldenStar",
  //   logo: GoldenStarLogo,
  //   ratings: [
  //     {
  //       name: "Game Variety",
  //       score: 6.5,
  //     },
  //     {
  //       name: "Game Quality",
  //       score: 7.5,
  //     },
  //     {
  //       name: "User Expiries",
  //       score: 9.5,
  //     },
  //     {
  //       name: "Bonuses",
  //       score: 6,
  //     },
  //     {
  //       name: "Slots",
  //       score: 8,
  //     },
  //   ],
  //   votes: 2506,
  //   paymentSystem: [paySafeCard, neteller, skrill, bitcoin, visa],
  //   description: "€300 + 100 Free Spins",
  //   descriptionSlider: "€300 \n + 100 Free Spins",
  //   Component: GoldenStar,
  //   review: "/goldenstar",
  //   link: "/go/goldenstar",
  //   profs: [
  //     "Drop & Wins promotions up to €4500",
  //     "Weekly Reload Bonus : 50 Free Spins",
  //     "Weekend Reload Bonus up to €700",
  //     "15% Weekly Cashback up to €3000",
  //     "First Deposit Bonus : a 100% bonus from your 1st deposit up to €500 + 200 Free Spins",
  //   ],
  //   backgroundColor: "#663399",
  // },
  // {
  //   name: "Bob-casino",
  //   logo: BobLogo,
  //   ratings: [
  //     {
  //       name: "Game Variety",
  //       score: 6.5,
  //     },
  //     {
  //       name: "Game Quality",
  //       score: 7.5,
  //     },
  //     {
  //       name: "User Expiries",
  //       score: 9.5,
  //     },
  //     {
  //       name: "Bonuses",
  //       score: 6,
  //     },
  //     {
  //       name: "Slots",
  //       score: 8,
  //     },
  //   ],
  //   votes: 2493,
  //   paymentSystem: [paySafeCard, neteller, skrill, visa, mastercard],
  //   description: "€500 + 130 Free Spins",
  //   descriptionSlider: "€500 \n + 130 Free Spins",
  //   Component: Bob,
  //   review: "/bobcasino",
  //   link: "/go/bobcasino",
  //   profs: [
  //     "Get Free Spins everyday",
  //     "Friday Reload: a 100% bonus up to €100",
  //     "Weekly Race by Bob-casino : get a share of €3000",
  //     "Sports & Casino Welcome Bonus: a 100% bonus up to €122",
  //     "Excellent betting limits",
  //   ],
  //   backgroundColor: "transparent",
  // },
];

const offerDa = [
  // 1
  {
    name: "WooCasino",
    logo: WooCasinoLogo,
    ratings: [
      {
        name: "Game Variety",
        score: 6.5,
      },
      {
        name: "Game Quality",
        score: 7.5,
      },
      {
        name: "User Expiries",
        score: 9.5,
      },
      {
        name: "Bonuses",
        score: 6,
      },
      {
        name: "Slots",
        score: 8,
      },
    ],
    votes: 2379,
    paymentSystem: [paySafeCard, neteller, skrill, bitcoin, visa],
    description: "100 € + 150 gratis spins",
    descriptionSlider: "100€ \n + 150 GRATIS SPINS",
    Component: WooCasino,
    review: "/woocasino",
    link: "/go/woo-casino",
    profs: [
      "Højvalseprogram. Særlige bonusser",
      "Turneringer med potentiale vinder op til € 1000",
      "Weekendopdatering: 50% bonus op til € 100 + 60 gratis spins",
      "Første indbetalingsbonus: en 100% bonus op til € 100 + 150 gratis spins",
      "Anden indbetalingsbonus: 50% bonus op til € 100 + 50 gratis spins",
    ],
    backgroundColor: "transparent",
  },
  {
    name: "Slottica",
    logo: SlotticaLogo,
    ratings: [
      {
        name: "Game Variety",
        score: 6.8,
      },
      {
        name: "Game Quality",
        score: 6.5,
      },
      {
        name: "User Expiries",
        score: 6.5,
      },
      {
        name: "Bonuses",
        score: 7,
      },
      {
        name: "Slots",
        score: 9,
      },
    ],
    votes: 2379,
    paymentSystem: [mastercard, paySafeCard, neteller, skrill, visa],
    description: "200% + 25 GRATIS SPINS",
    descriptionSlider: "200% \n + 25 GRATIS SPINS",
    Component: Slottica,
    review: "/slottica",
    link: "/go/slottica",
    profs: [
      "De mest effektive softwareleverandører",
      "Live chat er tilgængelig",
      "Mobilvenligt design",
      "Let at bruge lobby med flere filtre",
    ],
    backgroundColor: "transparent",
  },
  {
    name: "Cookie Casino",
    logo: CookieCasinoLogo,
    ratings: [
      {
        name: "Game Variety",
        score: 6.5,
      },
      {
        name: "Game Quality",
        score: 7.5,
      },
      {
        name: "User Expiries",
        score: 9.5,
      },
      {
        name: "Bonuses",
        score: 6,
      },
      {
        name: "Slots",
        score: 8,
      },
    ],
    votes: 2048,
    paymentSystem: [paySafeCard, skrill, visa, mastercard],
    description: "100% OP TIL 100 € \n + 120 GRATIS SPINS",
    descriptionSlider: "100% OP TIL 100 € \n + 120 GRATIS SPINS",
    Component: CookieCasino,
    review: "/cookie-casino",
    link: "/go/cookie-casino",
    profs: [
      "Hjem til førende softwareudviklere",
      "Tilbagetrækninger inden for 24 timer, en behandlet",
      "Brugervenlig grænseflade",
      "Mobilvenligt design",
    ],
    backgroundColor: "#008080",
  },
  {
    name: "Fortuneclock",
    logo: FortuneclockLogo,
    ratings: [
      {
        name: "Game Variety",
        score: 6.8,
      },
      {
        name: "Game Quality",
        score: 6.5,
      },
      {
        name: "User Expiries",
        score: 6.5,
      },
      {
        name: "Bonuses",
        score: 7,
      },
      {
        name: "Slots",
        score: 9,
      },
    ],
    votes: 2379,
    paymentSystem: [mastercard, visa],
    description: "225% + 225 GRATIS SPINS",
    descriptionSlider: "225% \n + 225 GRATIS SPINS",
    Component: FortuneClock,
    review: "/fortuneclock",
    link: "/go/fortuneclock",
    profs: [
      "Hjem til førende softwareudbydere",
      "Live chat er åben 24/7",
      "Mobilvenligt design",
      "Spil bevist fair",
    ],
    backgroundColor: "transparent",
  },
  {
    name: "TrueFlip",
    logo: TrueFlipLogo,
    ratings: [
      {
        name: "Game Variety",
        score: 6.5,
      },
      {
        name: "Game Quality",
        score: 7.5,
      },
      {
        name: "User Expiries",
        score: 9.5,
      },
      {
        name: "Bonuses",
        score: 6,
      },
      {
        name: "Slots",
        score: 8,
      },
    ],
    votes: 2457,
    paymentSystem: [bitcoin, visa],
    description: "OP TIL € 300 + 50 GRATIS SPINS",
    descriptionSlider: "OP TIL € 300 \n + 50 GRATIS SPINS",
    Component: TrueFlip,
    review: "/trueflip",
    link: "/go/trueflip",
    profs: [
      "VIP-bonus: 100% på deres indskud i denne fantastiske loyalitetsfunktion op til € 1000",
      "Kædens bonus: 50% oven på spillerens indbetaling op til € 100",
      "Henrys Bonus: speciel 75% depositum bonus op til € 250",
      "Velkomstpakke: 150% velkomstbonus op til € 300",
    ],
    backgroundColor: "#faebd7",
  },
];

export const offers = offer.map((offer, index) => ({
  ...offer,
  score: scores[index],
}));

export const offersDa = offerDa.map((offer, index) => ({
  ...offer,
  score: scores[index],
}));

import React from "react";

import TrackingLink from "../../components/TrackingLink";
import "./LvlUpContent.scss";
import { useWindowSize } from "../../utils/useWindowSize";

export const LvlUpContent = () => {
  const width = useWindowSize().width;
  return (
    <div className="lvlContainer">
      <div className="container">
        <h2 className="orange-text">LevelUp Casino Review</h2>
        <p>
          LevelUp casino got our attention as one of the fastest-growing online gambling
          platforms. Unlike many other websites, it doesn’t try to make a user bet by offering
          incredibly generous bonuses and attractive conditions. LevelUp doesn’t promise
          thousands of dollars for signing up and doesn’t make you think that you will win a
          jackpot immediately, right after you play one of the hundreds of games once or twice.
        </p>
        <p>
          That’s why it seems like a fair casino. But is it? What are the real welcome and
          reload bonuses offered to a player? Who are the software providers? Let us take a
          closer look at these and other important characteristics and find out if it’s
          trustworthy.
        </p>
        <TrackingLink className="btn" href="/go/lvlup">
          go to site
        </TrackingLink>

        <h3 className="orange-text">First Impression Of LevelUp Casino</h3>
        <p>
          At first, the site looks just like a regular casino. However, after we looked through
          the main pages we realized that this website is actually better than most sites owned
          by its competitors. Why? It is not messy at all. If you are a gambler, you know that
          it is nearly impossible to find the info you need on many sites that offer a wide
          game selection.
        </p>
        <div className="img">
          <TrackingLink href="/go/lvlup">
            {width > 769 ? (
              <img className="img-responsive" src="/images/vip.png" alt="vip" />
            ) : (
              <img className="img-responsive" src="/images/vip-mobile.png" alt="vip" />
            )}
          </TrackingLink>
        </div>
        <p>
          Information in T&C sections is well-structured and presented in a clear way — a rare
          thing for this industry. Games are divided into the groups, there is the search by
          the game developer, and overall, the sites make a good impression.
        </p>
        <TrackingLink className="btn" href="/go/lvlup">
          go to site
        </TrackingLink>
        <h3 className="orange-text">Game Selection At LevelUp Casino</h3>
        <p>
          Even if this casino is the most secure casino in the whole world, playing on this
          website doesn’t make much sense if there are no great games. So, what does this
          company have to offer to a gambler?
        </p>
        <p>
          The first thing we noticed was a huge number of the software providers LevelUp works
          with. There are over 35 software suppliers to choose from on the site, and you can
          see such big names as Yggdrasil, Playtech, and Play’n GO, as well as plenty of other
          less famous but still good companies creating quality games. There is a search filter
          for those who are looking for the products made by a particular provider.
        </p>
        <p>
          <b>All the games are divided into a few main categories:</b>
        </p>
        <ul>
          <li>
            <span>
              Slots: for those who are looking for hundreds of themed simple and fun games of
              chance
            </span>
          </li>
          <li>
            <span>
              Table: for gamblers who love playing games of skill in regular or in live mode
            </span>
          </li>
          <li>
            <span>Jackpot: for those who don’t mind playing progressive jackpot games</span>
          </li>
          <li>
            <span>
              Trending: for newbies and experienced players looking for the most popular
              options
            </span>
          </li>
          <li>
            <span>Bonus buy: for those who are looking for the most generous offers</span>
          </li>
          <li>
            <span>Bonus buy: for those who are looking for the most generous offers</span>
          </li>
          <li>
            <span>New: for old players looking for new products that the industry offers</span>
          </li>
        </ul>
        <p>
          There is nothing extraordinary about this classification, as well as the types of
          games offered by LevelUp — most competitors offer similar selection. However, there
          are a few things that make this casino stand out. First of all, there are really many
          games to choose from. After all, a casino that cooperates with 30+ providers is
          pretty rare. Secondly, despite this, the games are pretty quality and more
          importantly, fair. As we’ve noted above, it is a licensed company that must meet
          particular requirements, including the fairness of RNG and other types of games.
        </p>
        <h3 className="orange-text">Welcome Bonus At LevelUp Casino</h3>
        <p>
          In general, all bonuses at this casino can be divided into 2 main categories: welcome
          bonuses and reload bonuses.
        </p>
        <div className="img">
          <TrackingLink href="/go/lvlup">
            {width > 769 ? (
              <img className="img-responsive" src="/images/welcome.jpg" alt="welcome" />
            ) : (
              <img className="img-responsive" src="/images/welcome-mobile.png" alt="welcome" />
            )}
          </TrackingLink>
        </div>
        <p>So, what do new members who’ve just created their accounts get?</p>
        <ul>
          <li>
            <span>
              <b> First deposit</b> <br /> A new player needs to deposit 20 EUR or more to get
              a 100% bonus. The max bonus amount is 100 EUR, and it needs to be wagered 40
              times to become withdrawable. A player can also get up to 100 spins credited in 5
              parts daily. Deposit money is available for 14 days.
            </span>
          </li>
          <li>
            <span>
              <b> Second deposit </b>
              <br />A gambler who completed the 1st deposit bonus requirements needs to deposit
              20 EUR or more to get a 50% bonus, plus 50 free spins. The max bonus amount is
              100 EUR, and it needs to be wagered 40 times to become withdrawable. Bonus money
              is available for 14 days.
            </span>
          </li>
          <li>
            <span>
              <b>Third deposit </b> <br />A gambler who completed the 1st & 2nd deposit bonus
              requirements needs to deposit 20 EUR or more to get a 50% bonus. The max bonus
              amount is 100 EUR, and it needs to be wagered 40 times to become withdrawable.
              Bonus money is available for 14 days.
            </span>
          </li>
          <li>
            <span>
              <b> Fourth deposit </b> <br />A user who completed the 1st, 2nd & 3rd deposit
              bonus requirements needs to deposit 20 EUR or more to get a 100% bonus. The max
              bonus amount is 100 EUR, and it needs to be wagered 40 times to become
              withdrawable. Bonus money is available for 14 days. A player can also get up to
              50 spins in 2 parts which can be played within 1 day.
            </span>
          </li>
        </ul>
        <p>
          As you can see, this casino has a pretty good bonus system for new players. Some may
          say that this company is not as generous as other companies on the market that offer
          thousands of dollars. However, we all should consider that incredibly generous offers
          are the money you will hardly get. In this case, we can conclude that this is a
          gambling site with honest, real bonuses. As we have noted earlier, not only new
          members can benefit from the loyalty program. They can get the following bonuses:
        </p>
        <ul>
          <li>
            <span>
              Workaday reload — deposit bonus with 20 free spins, offer that runs from Monday
              to Thursday, the max amount is 100 EUR
            </span>
          </li>
          <li>
            <span>
              Weekend reload — deposit bonus with 40 free spins, available from Friday to
              Sunday, the max amount is 200 EUR
            </span>
          </li>
          <li>
            <span>
              Weekend special — deposit bonus with 40 free spins, available from Friday to
              Sunday, the max amount is 200 EUR
            </span>
          </li>
          <li>
            <span>
              Top weekend — deposit bonus with 50 free spins, available from Friday to Sunday,
              the max amount is 1,000 EUR
            </span>
          </li>
        </ul>
        <p>For more information on reload bonuses, visit the Bonuses T&C section.</p>
        <TrackingLink className="btn" href="/go/lvlup">
          go to site
        </TrackingLink>
        <h3 className="orange-text">Live Tables At The LevelUp Casino</h3>
        <p>
          Though there is no separate category for live table games at the casino, we could
          find a lot of great options in the Table Games section. A user can scroll down the
          page to see all the available games. Games in live mode are with a red mark on the
          preview image. We could find lots of variations of roulette, blackjack, baccarat, and
          poker. However, if you have a favorite table game you want to play in live mode, we
          recommend you type its name in the search box — the selection of games at this casino
          is wide, so this will save you a lot of time.
        </p>
        <h3 className="orange-text">Mobile Casino Games</h3>
        <p>
          At this casino, mobile users can play as many games as those who use the desktop
          version do. The team of LevelUp notes that providing the most exciting mobile
          gambling experience was one of their top priorities, and we must recognize that the
          company really offers lots of games compatible with most iOS and Android devices.
        </p>
        <h3 className="orange-text">Deposits And Withdrawals At LevelUp Casino</h3>
        <p>
          It is no secret that the number of payment methods, speed of withdrawals, as well as
          safety are the determining factors in choosing an online casino. So, what does the
          Level Up casino have to offer?
        </p>
        <p>
          First, let’s take a look at the deposit methods. There are 13 options to choose from,
          and all of them are “free”, which means you don’t have to pay any fees when
          depositing money to your account. All the payments are instant. The minimum deposit
          amount is €10, and the maximum amount is € 4,000.
        </p>
        <p>
          Please note that money withdrawing is a bit different from the depositing process.
          You need to verify your account by proving your identity, address, and payment
          details (just provide the copies of your documents.) This is a standard procedure for
          online gambling. The limits are the same — in most cases, €10 is the minimum amount,
          and € 4,000 is the maximum amount respectively. The only exception is bank transfer —
          you need to have at least €500 to use this method.
        </p>
        <p>
          As for processing time, though the team of the LevelUp casino aims to process
          withdrawals within 12 hours, it varies. This is common for all online casinos. At
          LevelUp, if you use your credit card, for example, Visa, MasterCard, or Maestro,
          you’ll need to wait up to 3 days to receive your money. Bank transfer will take up to
          5 days. If you have an e-wallet (Skrill, Neteller, Venus Point) or use one of the
          other available payment methods like Instadebit, iDebit, or Ecopayz, you’ll receive
          your money immediately or almost immediately.
        </p>
        <h3 className="orange-text">Customer Service At Levelup Casino</h3>
        <p>
          The only way to reach support is by filling in the contact form. All you need to do
          is to provide your email and describe your problem/ask your question. Though it is
          considered one of the slowest methods, the support team responds to the requests
          pretty quickly.
        </p>
        <h3 className="orange-text">Our Conclusion Of Levelup Casino</h3>
        <p>
          To sum up, it is one of the best options for Danish players, as well as the gamblers
          from other countries because safety and the widest game selection obviously are its
          top priorities. There are good bonuses, a lot of quality games, and the great support
          service is provided. So, it is definitely worth a try.
        </p>
        <TrackingLink className="btn" href="/go/lvlup">
          go to site
        </TrackingLink>
      </div>
    </div>
  );
};
